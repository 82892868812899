import React from "react";
import {
  Route,
  Navigate,
} from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";

// interface PrivateRouteProps {
//   component: React.FunctionComponent;
//   roles?: string;
// }

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({
  component: Component,
  roles,
  ...rest
}: any) => {
  const api = new APICore();

  return (
    <Route
      {...rest}
      render={(props: any) => {
        if (api.isUserAuthenticated() === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Navigate to={"/auth/login"} state={{ from: props["location"] }}
            // to={{
            //   pathname: "/auth/login",
            //   state: { from: props["location"] },
            // }}
            />
          );
        }

        const loggedInUser = api.getLoggedInUser();
        console.log('privateoure call')

        // check if route is restricted by role
        if (roles && roles.indexOf(loggedInUser.role) === -1) {
          // role not authorised so redirect to home page
          return <Navigate to={{ pathname: "/" }} />;
        }
        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
