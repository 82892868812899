// apicore
import { APICore } from "../../helpers/api/apiCore";
// action constants

// constant
import { AuthActionTypes } from "./constants";

const api = new APICore();

const INIT_STATE = {
  
  
  user: api.getLoggedInUser(),
  loading: false,
  originalEmail: '',
  userLoggedIn:'',
  userLogout:'',
};

interface UserData {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
}
interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.RESET_PASSWORD
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.STORE_ORIGINAL_EMAIL
    | AuthActionTypes.VERIFY_CONFIRMATION_CODE
    | AuthActionTypes.RESEND_CONFIRMATION_CODE;
    
  payload: {
    actionType?: string;
    data?: UserData | {};
    tokenise?: string |{};
    error?: string;
    email?:string;
    
    
    //AccessToken?: string;
    
    
  };
}
interface State {
  user?: UserData | {};
  loading?: boolean;
  value?: boolean;
  verificationStatus?: boolean;
  resendStatus?: boolean;
}

const Auth = (state: State = INIT_STATE, action: AuthActionType): any => {
  //console.log('Reducer Auth is invoked: ');
  //console.log('Reducer : Action Type:', action.type, action.payload, state);
  //console.log('Reducer : Current State:', state);

  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          const token = action.payload.data;
          
          //console.log('Reducer: User logged in successfully, TOKEN:', token);
          
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
            token,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          console.log('Reducer: SIGNUP_USER WITH SUCCESS');
          return {
            ...state,
            loading: false,
            userSignUp: true,
            error: null,
          };
        }
      
      
        case AuthActionTypes.LOGOUT_USER: {
          console.log('Reducer: LOGOUT_USER WITH SUCCESS');
          return {
            ...state,
            user: null,
            loading: false,
            userLogout: true,
            token: null,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          //console.log("Reducer: FORGOT_PASSWORD", action.payload);
          return {
            ...state,
            forgotPasswordSuccess: action.payload.data,
            loading: false,
            email: action.payload.email,
            passwordReset: true,
          };
        }
        case AuthActionTypes.RESET_PASSWORD: {
          //console.log("Reducer: RESET_PASSWORD", action.payload.email);
          return {
            ...state,
            loading: false,
            resetPasswordSuccess: true,
            email: action.payload.email,
            error: null,
          };
        }
        case AuthActionTypes.VERIFY_CONFIRMATION_CODE: {
          return {
            ...state,
            loading: false,
            verificationStatus: true,
            error: null,
          };
        }
        case AuthActionTypes.RESEND_CONFIRMATION_CODE: {
          return {
            ...state,
            loading: false,
            resendStatus: true,
            error: null,
          };
        }
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            error: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          console.log('Reducer: LOGOUTP_USER WITH ERROR', action.payload.error);
          
          return {
            ...state,
            
            error: action.payload.error,
            userLogout: false,
            loading: false,
            
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          console.log('Reducer: SIGNUP_USER WITH ERROR', action.payload.error);
          return {
            ...state,
            error: action.payload.error,
            success: false,
            loading: false,
          };
        }
        case AuthActionTypes.VERIFY_CONFIRMATION_CODE: {
          console.log('Reducer: VERIFY_CONFIRMATION_CODE WITH ERROR', action.payload.error);
          return {
            ...state,
            error:"Invalid code: Please re-enter valid 6 digits code sent to your email",
            //error: action.payload.error,
            loading: false,
            verificationStatus: null,
          };
        }
        case AuthActionTypes.RESEND_CONFIRMATION_CODE: {
          console.log('Reducer: RESEND_CONFIRMATION_CODE WITH ERROR', action.payload.error);
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            resendStatus: null,
          };
        }
        case AuthActionTypes.FORGOT_PASSWORD: {
          console.log('Reducer: FORGOT_PASSWORD WITH ERROR', action.payload.error);
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            email: action.payload.email,
            forgotPasswordSuccess: false,
          };
        }
        case AuthActionTypes.RESET_PASSWORD: {
          console.log('Reducer: RESET_PASSWORD WITH ERROR', action.payload.error);
          return {
            ...state,
            error: action.payload.error,
            resetPasswordSuccess: false,
            email: action.payload.email,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case AuthActionTypes.STORE_ORIGINAL_EMAIL: 
        //console.log('Reducer: STORE_ORIGINAL_EMAIL action triggered');
        //console.log('Current State:', state);
        //console.log('Action Payload:', action.payload);
        return {
          ...state,
          originalEmail: action.payload.email,
        };

      
  
      

    case AuthActionTypes.LOGIN_USER:
      return { ...state, loading: true, userLoggedIn: false };
    case AuthActionTypes.LOGOUT_USER:
      return { ...state, loading: true, userLogout: false };
    case AuthActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
        resetPasswordSuccess: null,
        verificationStatus: false,
        resendStatus: false,
      };
    case AuthActionTypes.VERIFY_CONFIRMATION_CODE:
      return { ...state, loading: true, verificationStatus: false };
    case AuthActionTypes.RESEND_CONFIRMATION_CODE:
      return { ...state, loading: true, resendStatus: false };
    default:
      return { ...state };
  }
};

export default Auth;



