import { APICore } from "./apiCore";

const api = new APICore();

// account
function login(params: { email: string; password: string }) {
  const baseUrl = "/user_login";
  return api.create(`${baseUrl}`, params);
}

function logout(params: { accessToken: string}) {
  const baseUrl = "/user_logout";
  return api.create(`${baseUrl}`, params);
}

function signup(params: {  email: string; password: string; name: string }) {
  //console.log('auth.ts: Sending payload to API Gateway:', params);
  const baseUrl = "/register";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
  const baseUrl = "/user_forgot_pass";
  return api.create(`${baseUrl}`, params);
}

function resetPassword(params: { email: string; verificationCode: string; newPassword: string }) {
  const baseUrl = "/user_reset_pass";
  return api.create(`${baseUrl}`, params);
}

function verifyConfirmationCode(params: { email: string; confirmationCode: string }) {
  const baseUrl = "/user_confirmed";
  return api.create(`${baseUrl}`, params);
}

function resendConfirmationCode(params: { email: string }) {
  const baseUrl = "/user_resend_code";
  return api.create(`${baseUrl}`, params);
}




export { login, logout, signup, forgotPassword, verifyConfirmationCode, resendConfirmationCode, resetPassword };
