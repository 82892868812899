import { all, fork, put, takeEvery, call, select } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { push } from 'connected-react-router';

// apicore
import { APICore, setAuthorization, validateToken } from "../../helpers/api/apiCore";

// helpers/ index.ts
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  verifyConfirmationCode as verifyConfirmationCodeApi,
  resendConfirmationCode as resendConfirmationCodeApi,
  resetPassword as resetPasswordApi,  // Import the resetPassword API call
} from "../../helpers/";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    username: string;
    password: string;
    name: string;
    email: string;
    confirmationCode: string;
    verificationCode: string;
    newPassword: string;
  };
  type: string;
}

const api = new APICore();

/**
 * Reset Password
 */
function* resetPassword({ payload: { email, verificationCode, newPassword } }: UserData): SagaIterator {
  try {
    const response = yield call(resetPasswordApi, { email, verificationCode, newPassword });
    yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, response.data));
    yield put(push('/auth/password-reset-success'));  // Navigate to success page
  } catch (error: any) {
    console.error('Saga: Reset password error:', error);
    yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
  }
}

// Existing sagas

// Login Saga
function* login({
  payload: { email, password },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { email, password });
    
    const user = response.data;
    // const token = user.data.AccessToken;
    const token = user.data.idToken;
    // console.log('Saga: RES:', user);
    // console.log('Saga: RES token:', token);
    
    if (token) {
      if (validateToken(token)) {
        api.setLoggedInUser({ token });
        setAuthorization(token);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, token));
        yield put(push('/dashboard'));
      } else {
        console.error('Saga: Token validation failed:', token);
        throw new Error('Saga: Invalid token specified');
      }
    } else {
      console.error('Saga: Token not found in response:', user);
      throw new Error('Saga: Invalid token specified');
    }
  } catch (error: any) {
    console.error('Saga: Login error:', error);
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

// Logout Saga
function* logout(): SagaIterator {
  try {
    const accessToken = yield select((state) => state.Auth.token);
    yield call(logoutApi, { accessToken });
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, { actionType: AuthActionTypes.LOGOUT_USER }));
  } catch (error: any) {
    console.error('Saga: Logout error:', error.message);
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error.message));
  }
}

// Signup Saga
function* signup({
  payload: { email, password, name },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { email, password, name });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    console.error('Saga.ts: Signup yield - Error caught from yield call() / apiCore.ts', error);
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

// Forgot Password Saga
function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email });
    yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

// Verify Confirmation Code Saga
function* verifyConfirmationCode({ payload: { email, confirmationCode } }: UserData): SagaIterator {
  try {
    const response = yield call(verifyConfirmationCodeApi, { email, confirmationCode });
    yield put(authApiResponseSuccess(AuthActionTypes.VERIFY_CONFIRMATION_CODE, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.VERIFY_CONFIRMATION_CODE, error.message));
  }
}

// Resend Confirmation Code Saga
function* resendConfirmationCode({ payload: { email } }: UserData): SagaIterator {
  try {
    const response = yield call(resendConfirmationCodeApi, { email });
    yield put(authApiResponseSuccess(AuthActionTypes.RESEND_CONFIRMATION_CODE, response.data));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.RESEND_CONFIRMATION_CODE, error));
  }
}

// Watchers
export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchVerifyConfirmationCode(): any {
  yield takeEvery(AuthActionTypes.VERIFY_CONFIRMATION_CODE, verifyConfirmationCode);
}

export function* watchResendConfirmationCode(): any {
  yield takeEvery(AuthActionTypes.RESEND_CONFIRMATION_CODE, resendConfirmationCode);
}

export function* watchResetPassword(): any {
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);  // Watch for RESET_PASSWORD action
}

// Combine all watchers
function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchVerifyConfirmationCode),
    fork(watchResendConfirmationCode),
    fork(watchResetPassword),  // Include the new watcher
  ]);
}

export default authSaga;
