// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.RESET_PASSWORD
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER
    | AuthActionTypes.STORE_ORIGINAL_EMAIL
    | AuthActionTypes.VERIFY_CONFIRMATION_CODE
    | AuthActionTypes.RESEND_CONFIRMATION_CODE
    
  payload: {} | string;
}

interface UserData {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
  confirmationCode: string;
  verificationCode: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  name: string,
  email: string,
  password: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: {  email, password, name },
});

export const forgotPassword_auth = (email: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email },
});

export const storeOriginalEmail = (email: string): AuthActionType => {
  //console.log('Action Creator: STORE_ORIGINAL_EMAIL with email:', email);
  return{
  type: AuthActionTypes.STORE_ORIGINAL_EMAIL,
  payload: { email },
  };
};

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});

export const resetPassword = (
  email: string,
  verificationCode: string,
  newPassword: string
): AuthActionType => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: { email, verificationCode, newPassword },
});

// New actions for verifying and resending confirmation code
export const verifyConfirmationCode = (
  email: string,
  confirmationCode: string
): AuthActionType => ({
  type: AuthActionTypes.VERIFY_CONFIRMATION_CODE,
  payload: { email, confirmationCode},
});

export const resendConfirmationCode = (email: string): AuthActionType => ({
  type: AuthActionTypes.RESEND_CONFIRMATION_CODE,
  payload: { email },
});


