export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@auth/API_RESPONSE_ERROR",

  LOGIN_USER = "@@auth/LOGIN_USER",
  LOGOUT_USER = "@@auth/LOGOUT_USER",
  SIGNUP_USER = "@@auth/SIGNUP_USER",
  FORGOT_PASSWORD = "@@auth/FORGOT_PASSWORD",
  RESET_PASSWORD = "@@auth/RESET_PASSWORD",

  RESET = "@@auth/RESET",
  STORE_ORIGINAL_EMAIL = "@@auth/STORE_ORIGINAL_EMAIL",
  VERIFY_CONFIRMATION_CODE = "@@auth/VERIFY_CONFIRMATION_CODE",
  RESEND_CONFIRMATION_CODE = "@@auth/RESEND_CONFIRMATION_CODE",
  //CHANGE_AUTH_LAYOUT_THEME = "@@auth/CHANGE_AUTH_LAYOUT_THEME",
}
