import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../../config";

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;

axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    let message;
    if (error && error.response) {
      if (error.response.status === 404) {
        window.location.href = '/not-found';
      } else if (error.response.status === 403) {
        window.location.href = "/access-denied";
      } else if (error.response.status === 401) {
        console.warn("Access token expired. Redirecting to login.");
        sessionStorage.removeItem(AUTH_SESSION_KEY); // Clear session storage
        window.location.href = '/auth/login'; // Redirect to login page
      } else {
        switch (error.response.status) {
          case 401:
            message = "Invalid credentials";
            break;
          case 403:
            message = "Access Forbidden";
            break;
          case 404:
            message = "Sorry! the data you are looking for could not be found";
            break;
          default: {
            message =
              error.response && error.response.data
                ? error.response.data["message"]
                : error.message || error;
          }
        }
      }
    }
    return Promise.reject(message);
  }
);

const AUTH_SESSION_KEY = "aiiotlab_user";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: string | null) => {
  if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  else delete axios.defaults.headers.common["Authorization"];
};

const getUserFromCookie = () => {
  const user = sessionStorage.getItem(AUTH_SESSION_KEY);
  return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

/**
 * Validate the JWT token format
 * @param token - JWT token
 * @returns boolean - true if valid, false otherwise
 */
export const validateJWTToken = (token: string): boolean => {
  try {
    console.log("apiCore.ts: Decoded");
    return true;
  } catch (e) {
    console.error("apiCore.ts: Invalid token format:", e);
    return false;
  }
};

class APICore {
  get = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }
    return response;
  };

  getFile = (url: string, params: any) => {
    let response;
    if (params) {
      const queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls: string, params: any) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  create = (url: string, data: any) => {
    return axios.post(url, data)
      .then(response => {
        const message = ((response.data as { message?: string }).message || 
                (response.data as { data?: { message?: string }}).data?.message || 
                'No message available');
       
         console.log('apiCore.ts: API RES:', { status: response.status, message: message });
         return response;
      })
      .catch(error => {
        console.error('apiCore.ts: API Gateway ERROR:', error);
        throw error;
      });
  };

  updatePatch = (url: string, data: any) => {
    return axios.patch(url, data);
  };

  update = (url: string, data: any) => {
    return axios.put(url, data);
  };

  delete = (url: string) => {
    return axios.delete(url);
  };

  createWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.post(url, formData, config);
  };

  updateWithFile = (url: string, data: any) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config: any = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();

    if (!user) {
      return false;
    }
    const decoded: any = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      sessionStorage.removeItem(AUTH_SESSION_KEY); // Clear session storage
      window.location.href = '/auth/login'; // Redirect to login page
      return false;
    } else {
      return true;
    }
  };

  setLoggedInUser = (session: any) => {
    if (session)
      sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      sessionStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  getLoggedInUser = () => {
    return getUserFromCookie();
  };

  setUserInSession = (modifiedUser: any) => {
    let userInfo = sessionStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

export { APICore, setAuthorization, validateJWTToken as validateToken };
